import React from "react";
import History from "../components/history";
import Footer from "../components/footer";
import TopNav from "../components/topnavbar";
import Navbar from "../components/navbar";
import Testmoniale from "../components/testmoniale";
function About() {
  return (
    <>
      <TopNav />
      <Navbar />
      <div
        class="banner-header valign bg-img bg-fixed"
        data-overlay-dark="5"
        data-background="img/banner3.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-end caption">
              <h1>
                <span>Tailorand</span> Tailoring
              </h1>
              <h5>Experience you can trust</h5>
            </div>
          </div>
        </div>
      </div>

      <section class="about section-padding" data-scroll-index="1">
        <div class="container">
          <div class="row">
            <div
              class="col-md-6 mb-30 animate-box"
              data-animate-effect="fadeInUp"
            >
              <div class="section-head mb-20">
                <div class="section-subtitle">Tailors to Trust</div>
                <div class="section-title">
                  Tailorand <span>Tailoring</span>
                </div>
              </div>
              <p>
                Tailor sit amet risus ac dui auctor posuere sit amet eget
                libero. Ut lacinia lectusan risus facilisis, semper consequat
                sem fringilla. Etiam et tincidunt felis. Quisque at maximus
                nulla dictum vestibulum sed interdum neque dictum.
              </p>
              <p>
                Tailiorand lobortis sollicitudin purus vel posuere. Maecenas
                auctor, turpis quis mattis tristique, ligula dolor vestibulum
                risus, nec ullamcorper justo dolor soda lorem. Sed interdum arcu
                ac metus mollis venenatis.
              </p>
              <ul class="about-list list-unstyled mb-20">
                <li>
                  <div class="about-list-icon">
                    {" "}
                    <span class="ti-check"></span>{" "}
                  </div>
                  <div class="about-list-text">
                    <p>Ten decades of experience in luxury tailoring</p>
                  </div>
                </li>
                <li>
                  <div class="about-list-icon">
                    {" "}
                    <span class="ti-check"></span>{" "}
                  </div>
                  <div class="about-list-text">
                    <p>We care about our customers satisfaction</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-6 animate-box" data-animate-effect="fadeInUp">
              <div class="row img-twice position-relative h-100">
                <div class="col-6 animate-box" data-animate-effect="fadeInUp">
                <img class="img-fluid" src="img/_T7A5313.jpg" alt="" />

                </div>
                <div
                  class="col-6 align-self-end animate-box"
                  data-animate-effect="fadeInUp"
                >
                  <img class="img-fluid" src="img/_T7A5312.jpg" alt="" />

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <History />
      <Testmoniale/>
      <section class="services-box mt-30 mb-30">
        <div class="container">
          <div class="row">
            <div class="col-md-4 animate-box" data-animate-effect="fadeInUp">
              <div class="item">
                {" "}
                <span class="icon flaticon-money"></span>
                <div class="cont">
                  <h5>Best Price</h5>
                  <p>
                    Best price nisl quam nestibulum drana elementum sceisue the
                    monte.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 animate-box" data-animate-effect="fadeInUp">
              <div class="item">
                {" "}
                <span class="icon flaticon-blazer"></span>
                <div class="cont">
                  <h5>Best Fabric</h5>
                  <p>
                    Best fabric nisl quam nestibulum drana odio elementum monte.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 animate-box" data-animate-effect="fadeInUp">
              <div class="item">
                {" "}
                <span class="icon flaticon-sewing-machine"></span>
                <div class="cont">
                  <h5>Best Tailors</h5>
                  <p>
                    Best tailors nisl quam nestibulum drana odio elementum
                    sceisue the can.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}
export default About;