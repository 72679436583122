import React from "react";
import Footer from "../components/footer";
import TopNav from "../components/topnavbar";
import Navbar from "../components/navbar";
function Porfolio() {
  return (
    <>
      <TopNav />
      <Navbar />
      <div
        class="banner-header valign bg-img bg-fixed"
        data-overlay-dark="4"
        data-background="img/slider/25.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-end caption">
              <h1>
                Our <span>Tailor</span> Portfolio
              </h1>
              <h5>Gallery & Video</h5>
            </div>
          </div>
        </div>
      </div>

      <section class="section-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-head">
                <div class="section-subtitle">Portfolio</div>
                <div class="section-title">Image Gallery</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 gallery-item">
              <a href="img/slider/1.jpg" title="" class="img-zoom">
                <div class="gallery-box">
                  <div class="gallery-img">
                    {" "}
                    <img
                      src="img/slider/1.jpg"
                      class="img-fluid mx-auto d-block"
                      alt="work-img"
                    />{" "}
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-4 gallery-item">
              <a href="img/slider/2.jpg" title="" class="img-zoom">
                <div class="gallery-box">
                  <div class="gallery-img">
                    {" "}
                    <img
                      src="img/slider/2.jpg"
                      class="img-fluid mx-auto d-block"
                      alt="work-img"
                    />{" "}
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-4 gallery-item">
              <a href="img/slider/3.jpg" title="" class="img-zoom">
                <div class="gallery-box">
                  <div class="gallery-img">
                    {" "}
                    <img
                      src="img/slider/3.jpg"
                      class="img-fluid mx-auto d-block"
                      alt="work-img"
                    />{" "}
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-4 gallery-item">
              <a href="img/slider/4.jpg" title="" class="img-zoom">
                <div class="gallery-box">
                  <div class="gallery-img">
                    {" "}
                    <img
                      src="img/slider/4.jpg"
                      class="img-fluid mx-auto d-block"
                      alt="work-img"
                    />{" "}
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-4 gallery-item">
              <a href="img/slider/11.jpg" title="" class="img-zoom">
                <div class="gallery-box">
                  <div class="gallery-img">
                    {" "}
                    <img
                      src="img/slider/11.jpg"
                      class="img-fluid mx-auto d-block"
                      alt="work-img"
                    />{" "}
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-4 gallery-item">
              <a href="img/slider/6.jpg" title="" class="img-zoom">
                <div class="gallery-box">
                  <div class="gallery-img">
                    {" "}
                    <img
                      src="img/slider/6.jpg"
                      class="img-fluid mx-auto d-block"
                      alt="work-img"
                    />{" "}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding bg-lightbrown">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-head">
                <div class="section-subtitle">Portfolio</div>
                <div class="section-title">Video Gallery</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="vid-area">
                <div class="vid-icon">
                  {" "}
                  <img src="img/t1.jpg" alt="YouTube" />
                  <a
                    class="video-gallery-button vid"
                    href="https://youtu.be/mar7PWg5Znw"
                  >
                    {" "}
                    <span class="video-gallery-polygon">
                      <i class="ti-control-play"></i>
                    </span>{" "}
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="vid-area">
                <div class="vid-icon">
                  {" "}
                  <img src="img/t2.jpg" alt="YouTube" />
                  <a
                    class="video-gallery-button vid"
                    href="https://youtu.be/mar7PWg5Znw"
                  >
                    {" "}
                    <span class="video-gallery-polygon">
                      <i class="ti-control-play"></i>
                    </span>{" "}
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="vid-area">
                <div class="vid-icon">
                  {" "}
                  <img src="img/t3.jpg" alt="YouTube" />
                  <a
                    class="video-gallery-button vid"
                    href="https://youtu.be/mar7PWg5Znw"
                  >
                    {" "}
                    <span class="video-gallery-polygon">
                      <i class="ti-control-play"></i>
                    </span>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

    </>
  );
}

export default Porfolio;
