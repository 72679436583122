import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Admin from "./admin";
import About from "./pages/about";
import Contact from "./pages/contact";
import Blog from "./pages/blog";
import Porfolio from "./pages/porfolio";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={ <About/>  } />
        <Route path="/contact" element={<Contact/> } />
        <Route path="/gallery" element= {<Porfolio/>  } />
        <Route path="/blog" element= { <Blog/> } />
        <Route path="/admin" element={ <Admin/>  } />
      </Routes>
    </Router>
  );
}

export default App;
