import React from "react";

function Service() {
    return(
        <>
        <section class="services section-padding bg-lightbrown">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-head text-center">
                        <div class="section-subtitle">What we do</div>
                        <div class="section-title">Our <span>Services</span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="owl-carousel owl-theme">
                        <div class="item right">
                            <figure><img src="img/slider/3.jpg" alt="" class="img-fluid"/></figure>
                            <div class="caption padding-left">
                                <div class="title">Men’s Tailoring</div>
                                <p>Bespoke Tailor offers a full range of men’s tailoring services including suits, jackets, shirts, ties and accessories. Miss orci elit silver tristiue in the dream vitaen aliuam lorem tincidunt felis sed gravida aluam the neque miss blue hendren mavition.</p> 
                                <a href="services-page.html" class="button-1">Discover<span></span></a>
                            </div>
                        </div>
                        <div class="item right">
                            <figure><img src="img/slider/6.jpg" alt="" class="img-fluid"/></figure>
                            <div class="caption padding-left">
                                <div class="title">Wedding Dress Alterations</div>
                                <p>Bespoke Tailor offers a complete wedding dress alterations and repair service. Alisuen miss orci elit gene on tristique in the dream vitaen aliuam lorem tincidunt felis sed gravida aliquam the neque miss blue hendren mavition duru sapien mana amenta.</p> <a href="services-page.html" class="button-1">Discover<span></span></a>
                            </div>
                        </div>
                        <div class="item right">
                            <figure><img src="img/slider/20.jpg" alt="" class="img-fluid"/></figure>
                            <div class="caption padding-left">
                                <div class="title">Clothing Design & Repairs</div>
                                <p>For the best in unique or custom hand-made pieces. Carefully designed to be versatile and easy to match to any outfit. Lorem tincidunt felis sed gravida aliquam the neque miss blue hendren mavition duru sapien mana amenta sapien.</p> <a href="services-page.html" class="button-1">Discover<span></span></a>
                            </div>
                        </div>
                        <div class="item right">
                            <figure><img src="img/slider/17.jpg" alt="" class="img-fluid"/></figure>
                            <div class="caption padding-left">
                                <div class="title">Ladies Tailoring</div>
                                <p>From a sleek, satin evening dress with exquisite detail, to a sharp classical tailored suit. Lorem tincidunt felis sed gravida aliquam the neque miss blue hendren mavition duru sapien mana amenta blue hendren mavition duru sapien mana the mollis.</p> <a href="services-page.html" class="button-1">Discover<span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="services-box mt-30 mb-30">
        <div class="container">
            <div class="row">
                <div class="col-md-4 animate-box" data-animate-effect="fadeInUp">
                    <div class="item"> <span class="icon flaticon-money"></span>
                        <div class="cont">
                            <h5>Best Price</h5>
                            <p>Best price nisl quam nestibulum drana elementum sceisue the monte.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 animate-box" data-animate-effect="fadeInUp">
                    <div class="item"> <span class="icon flaticon-blazer"></span>
                        <div class="cont">
                            <h5>Best Fabric</h5>
                            <p>Best fabric nisl quam nestibulum drana odio elementum monte.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 animate-box" data-animate-effect="fadeInUp">
                    <div class="item"> <span class="icon flaticon-sewing-machine"></span>
                        <div class="cont">
                            <h5>Best Tailors</h5>
                            <p>Best tailors nisl quam nestibulum drana odio elementum sceisue the can.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
    );
}

export default Service;