import React from "react";

function Gallery() {
    return(

        <>
         <section class="section-padding bg-lightbrown">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="section-head">
                        <div class="section-subtitle">Our Portfolio</div>
                        <div class="section-title">Tailor Gallery</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 gallery-item">
                    <a href="img/slider/1.jpg" title="" class="img-zoom">
                        <div class="gallery-box">
                            <div class="gallery-img"> <img src="img/slider/1.jpg" class="img-fluid mx-auto d-block" alt="work-img"/> </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-4 gallery-item">
                    <a href="img/slider/2.jpg" title="" class="img-zoom">
                        <div class="gallery-box">
                            <div class="gallery-img"> <img src="img/slider/2.jpg" class="img-fluid mx-auto d-block" alt="work-img"/> </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-4 gallery-item">
                    <a href="img/slider/3.jpg" title="" class="img-zoom">
                        <div class="gallery-box">
                            <div class="gallery-img"> <img src="img/slider/3.jpg" class="img-fluid mx-auto d-block" alt="work-img"/> </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-4 gallery-item">
                    <a href="img/slider/4.jpg" title="" class="img-zoom">
                        <div class="gallery-box">
                            <div class="gallery-img"> <img src="img/slider/4.jpg" class="img-fluid mx-auto d-block" alt="work-img"/> </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-4 gallery-item">
                    <a href="img/slider/11.jpg" title="" class="img-zoom">
                        <div class="gallery-box">
                            <div class="gallery-img"> <img src="img/slider/11.jpg" class="img-fluid mx-auto d-block" alt="work-img"/> </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-4 gallery-item">
                    <a href="img/slider/25.jpg" title="" class="img-zoom">
                        <div class="gallery-box">
                            <div class="gallery-img"> <img src="img/slider/25.jpg" class="img-fluid mx-auto d-block" alt="work-img"/> </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
        
        </>
    );
}

export default Gallery;