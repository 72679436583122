import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import de l'authentification
import { getDatabase } from "firebase/database"; // Import de la base de données
import { getAnalytics } from "firebase/analytics"; // Import des analytics
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore"; // Import Firestore

// Votre configuration Firebase
const firebaseConfig = {
    apiKey: "AIzaSyDjMhFkjiBCuROFt9c7UkO4vXmKmAXv7uc",
    authDomain: "taecoutures.firebaseapp.com",
    projectId: "taecoutures",
    databaseURL: "https://taecoutures-default-rtdb.firebaseio.com",
    storageBucket: "taecoutures.firebasestorage.app",
    messagingSenderId: "1024791995711",
    appId: "1:1024791995711:web:3acae1d6032093279f9f8a",
    measurementId: "G-LNMBT7K6Y8",
};

// Initialisation de Firebase
const app = initializeApp(firebaseConfig);

// Initialisation des services Firebase
const db = getDatabase(app); // Base de données
const auth = getAuth(app); // Authentification
const analytics = getAnalytics(app); // Analytics (optionnel)
const firestore = getFirestore(app); // Firestore (optionnel)

// Exporter l'application et les services nécessaires
export { app, db, auth, analytics, firestore };
