import React from "react";

function Footer() {
    return(
        <>
        <footer class="footer">
        <div class="footer-second">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="footer-column footer-contact">
                            <h3 class="footer-title">Contact</h3>
                            <p class="footer-contact-text">Akpakpa Lomnava Lot 74
                                <br/>Cotonou Benin
                            </p>
                            <div class="footer-contact-info">
                                <p class="footer-contact-phone"><span class="flaticon-phone-call"></span> +229 01 61 05 78 36</p>
                                <p class="footer-contact-mail"> info@taecoutures.com</p>
                            </div>
                            <div class="footer-about-social-list"> <a href="#"><i class="ti-instagram"></i></a> <a href="#"><i class="ti-twitter"></i></a> <a href="#"><i class="ti-youtube"></i></a> <a href="#"><i class="ti-facebook"></i></a> <a href="#"><i class="ti-pinterest"></i></a> </div>
                        </div>
                    </div>
                    <div class="col-md-3 offset-md-1">
                        <div class="item opening">
                            <h3 class="footer-title">Work Time</h3>
                            <ul>
                                <li>
                                    <div class="tit">Monday</div>
                                    <div class="dots"></div> <span>10:00 - 20:00</span>
                                </li>
                                <li>
                                    <div class="tit">Tuesday</div>
                                    <div class="dots"></div> <span>10:00 - 20:00</span>
                                </li>
                                <li>
                                    <div class="tit">Thursday</div>
                                    <div class="dots"></div> <span>10:00 - 20:00</span>
                                </li>
                                <li>
                                    <div class="tit">Friday</div>
                                    <div class="dots"></div> <span>10:00 - 20:00</span>
                                </li>
                                <li>
                                    <div class="tit">Saturday</div>
                                    <div class="dots"></div> <span>10:00 - 20:00</span>
                                </li>
                                <li>
                                    <div class="tit">Weekend</div>
                                    <div class="dots"></div> <span>Closed</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4 offset-md-1">
                        <div class="footer-column footer-explore clearfix">
                            <h3 class="footer-title">Subscribe</h3>
                            <div class="row subscribe">
                                <div class="col-md-12">
                                    <p>Subscribe to take advantage of our campaigns and gift certificates.</p>
                                    <form>
                                        <input type="text" name="search" placeholder="Your email" required />
                                        <button>Subscribe</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="footer-bottom-inner">
                            <p class="footer-bottom-copy-right">2024 © All rights reserved. Designed by <a href="#" target="_blank">GAZOLINE-HOSTING</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
        </>
    );
    
}

export default Footer;