import React from "react";

function Services() {
    return(
        <>
         <section class="services2 section-padding">
        <div class="container">
            <div class="row">
                <div class="col-md-12 animate-box" data-animate-effect="fadeInUp">
                    <div class="section-head text-center">
                        <div class="section-subtitle">Our Services</div>
                        <div class="section-title">We Also Offer</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 animate-box" data-animate-effect="fadeInUp">
                    <div class="item">
                        <a href="services-page.html"> <span class="icon flaticon-blazer"></span>
                            <h5>Jacket Alterations</h5>
                            <div class="shape"> <span class="icon flaticon-blazer"></span> </div>
                        </a>
                    </div>
                </div>
                <div class="col-md-4 animate-box" data-animate-effect="fadeInUp">
                    <div class="item">
                        <a href="services-page.html"> <span class="icon flaticon-suit"></span>
                            <h5>Designer Alterations</h5>
                            <div class="shape"> <span class="icon flaticon-suit"></span> </div>
                        </a>
                    </div>
                </div>
                <div class="col-md-4 animate-box" data-animate-effect="fadeInUp">
                    <div class="item">
                        <a href="services-page.html"> <span class="icon flaticon-dress"></span>
                            <h5>Bridal Alterations</h5>
                            <div class="shape"> <span class="icon flaticon-dress"></span> </div>
                        </a>
                    </div>
                </div>
                <div class="col-md-4 animate-box" data-animate-effect="fadeInUp">
                    <div class="item">
                        <a href="services-page.html"> <span class="icon flaticon-jacket"></span>
                            <h5>Wear & Gown Alterations</h5>
                            <div class="shape"> <span class="icon flaticon-jacket"></span> </div>
                        </a>
                    </div>
                </div>
                <div class="col-md-4 animate-box" data-animate-effect="fadeInUp">
                    <div class="item">
                        <a href="services-page.html"> <span class="icon flaticon-sewing-machine"></span>
                            <h5>Dress Alterations</h5>
                            <div class="shape"> <span class="icon flaticon-sewing-machine"></span> </div>
                        </a>
                    </div>
                </div>
                <div class="col-md-4 animate-box" data-animate-effect="fadeInUp">
                    <div class="item">
                        <a href="services-page.html"> <span class="icon flaticon-scissors"></span>
                            <h5>Dress Repair</h5>
                            <div class="shape"> <span class="icon flaticon-scissors"></span> </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
    );
} 
export default Services;