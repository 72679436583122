import React from "react";
import { Menu } from "@headlessui/react";
import Card from "./components/card";
import BlogList from "./components/blogs";
import FormComponent from "./forms";
import "./index.css";
function Admin() {
  const blogs = [
    {
      id: 1,
      title: "Mon premier blog",
      description:
        "Ceci est un exemple de description de blog qui est plus longue et contient plus d'informations.",
      image: "https://via.placeholder.com/400/0000FF/808080?Text=Image1",
    },
    {
      id: 2,
      title: "Deuxième blog",
      description:
        "Une autre description de blog ici pour illustrer un autre article.",
      image: "https://via.placeholder.com/400/FF0000/FFFFFF?Text=Image2",
    },
    {
      id: 3,
      title: "Blog numéro trois",
      description: "Encore une autre description pour démontrer l'exemple.",
      image: "https://via.placeholder.com/400/00FF00/000000?Text=Image3",
    },
  ];
  const handleEdit = (id) => {
    console.log(`Modifier l'article avec ID: ${id}`);
  };

  const handleDelete = (id) => {
    console.log(`Supprimer l'article avec ID: ${id}`);
  };
  return (
    <>
      <div id="viewport">
        <div id="sidebar">
          <header>
            <a href="#">My App</a>
          </header>
          <ul class="nav">
            <li>
              <a href="#">
                <i class="zmdi zmdi-view-dashboard"></i> Dashboard
              </a>
            </li>
            <li>
              <a href="#">
                <i class="zmdi zmdi-link"></i> Shortcuts
              </a>
            </li>
            <li>
              <a href="#">
                <i class="zmdi zmdi-widgets"></i> Overview
              </a>
            </li>
            <li>
              <a href="#">
                <i class="zmdi zmdi-calendar"></i> Events
              </a>
            </li>
          </ul>
        </div>
        <div id="content">
          <nav class="navbar navbar-default">
            <div class="container-fluid"></div>
          </nav>
          <div class="container-fluid">
            <h1></h1>
            <FormComponent/>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
