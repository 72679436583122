import React, { useState } from "react";
import TopNav from "../components/topnavbar";
import Navbar from "../components/navbar";
import Service from "../components/services";
import Section from "../components/sections";
import Testmoniale from "../components/testmoniale";
import Gallery from "../components/galerie";
import Services from "../components/servicess";
import Blog from "../components/blog";
import Forms from "../components/form";
import Footer from "../components/footer";

function Home() {
    return(
        <>
        <TopNav/>
        <Navbar/>
        <header class="header slider-fade">
        <div class="owl-carousel owl-theme">
            <div class="item bg-img" data-overlay-dark="3" data-background="img/slider/400.jpg">
                <div class="v-middle caption">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-md-8 text-center"> 
                                <h6>Be the perfect groom on your big day</h6>
                                <h1>Men's Wear Alterations<br/><span>and Tailoring</span></h1> 
                                <a href="services-page.html" class="button-4">Make Appointment<span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item bg-img" data-overlay-dark="4" data-background="img/slider/800.jpg">
                <div class="v-middle caption">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-md-8 text-center"> 
                                <h6>Cut, Tailored & Fitted</h6>
                                <h1>Perfectly <span>Tailored</span><br/>Clothing</h1> 
                                <a href="services-page.html" class="button-4">Make Appointment<span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item bg-img" data-overlay-dark="4" data-background="img/slider/900.jpg">
                <div class="v-middle caption">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-md-8 text-center"> 
                                <h6>Tailors to Trust</h6>
                                <h1>Bespoke <span>Wedding</span><br/>Attire</h1> 
                                <a href="services-page.html" class="button-4">Make Appointment<span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section class="about section-padding" data-scroll-index="1">
        <div class="container">
          <div class="row">
            <div
              class="col-md-6 mb-30 animate-box"
              data-animate-effect="fadeInUp"
            >
              <div class="section-head mb-20">
                <div class="section-subtitle">Tailors to Trust</div>
                <div class="section-title">
                  Tailorand <span>Tailoring</span>
                </div>
              </div>
              <p>
                Tailor sit amet risus ac dui auctor posuere sit amet eget
                libero. Ut lacinia lectusan risus facilisis, semper consequat
                sem fringilla. Etiam et tincidunt felis. Quisque at maximus
                nulla dictum vestibulum sed interdum neque dictum.
              </p>
              <p>
                Tailiorand lobortis sollicitudin purus vel posuere. Maecenas
                auctor, turpis quis mattis tristique, ligula dolor vestibulum
                risus, nec ullamcorper justo dolor soda lorem. Sed interdum arcu
                ac metus mollis venenatis.
              </p>
              <ul class="about-list list-unstyled mb-20">
                <li>
                  <div class="about-list-icon">
                    {" "}
                    <span class="ti-check"></span>{" "}
                  </div>
                  <div class="about-list-text">
                    <p>Ten decades of experience in luxury tailoring</p>
                  </div>
                </li>
                <li>
                  <div class="about-list-icon">
                    {" "}
                    <span class="ti-check"></span>{" "}
                  </div>
                  <div class="about-list-text">
                    <p>We care about our customers satisfaction</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-6 animate-box" data-animate-effect="fadeInUp">
              <div class="row img-twice position-relative h-100">
                <div class="col-6 animate-box" data-animate-effect="fadeInUp">
                <img class="img-fluid" src="img/_T7A5313.jpg" alt="" />

                </div>
                <div
                  class="col-6 align-self-end animate-box"
                  data-animate-effect="fadeInUp"
                >
                  <img class="img-fluid" src="img/_T7A5312.jpg" alt="" />

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        <Service/>
        <Section/>
        <Testmoniale/>
        <Gallery/>
        <Services/>
        <Blog/>
        <Forms/>
        <Footer/>
        </>
    );
    
}

export default Home;