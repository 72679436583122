import React from "react";

function Testmoniale() {

    return(
        <>
        <section class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-head">
                        <div class="section-subtitle">Testimonials</div>
                        <div class="section-title">What Our <span>Clients Say</span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="testimonials left">
                        <figure> <img src="img/slider/7000.png" style={{height: "990px" }} alt="" class="img-fluid"/> </figure>
                        <div class="caption testimonials">
                            <div class="owl-carousel owl-theme">
                                <div class="item-box"> <span class="quote"><img src="img/quot.png" alt=""/></span>
                                    <h5>"Highly recommend, thank you again!"</h5>
                                    <p>Betty is so lovely and did such a great job with my wedding dress along with bridal party and mother of the bride outfits... highly recommend, thank you again!</p>
                                    <div class="info">
                                        <div class="author-img"> <img src="img/team/8.png" alt=""/> </div>
                                        <div class="cont">
                                            <h6>William Brown</h6> <span>Customer Review</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-box"> <span class="quote"><img src="img/quot.png" alt=""/></span>
                                    <h5>"Martin is very thorough with her work and done an amazing job"</h5>
                                    <p>Martin is very thorough with her work and done an amazing job with my groom dresses, would highly recommend! Thank you again.</p>
                                    <div class="info">
                                        <div class="author-img"> <img src="img/team/9.png" alt=""/> </div>
                                        <div class="cont">
                                            <h6>Amelia Emma</h6> <span>Customer Review</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-box"> <span class="quote"><img src="img/quot.png" alt=""/></span>
                                    <h5>"Betty is an AMAZING wizard with a sewing machine!"</h5>
                                    <p>Betty is so lovely and did such a great job with my wedding dress along with bridal party and mother of the bride outfits...</p>
                                    <div class="info">
                                        <div class="author-img"> <img src="img/team/7.png" alt=""/> </div>
                                        <div class="cont">
                                            <h6>Noah Martin</h6> <span>Customer Review</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
    );
    
}

export default Testmoniale;