import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { ref, get } from "firebase/database";
import { app, db } from "./firebase"; // On garde la référence à db venant de firebase/database

function Section() {
  const [menuTabs, setMenuTabs] = useState([]);
  const [toast, setToast] = useState({ show: false, message: "", variant: "" });

  useEffect(() => {
    const teamRef = ref(db, "menuTabs"); // Référence vers le chemin des onglets dans la base de données
    get(teamRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val(); // Données récupérées
          const tabsArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key], // Ajoute les données de chaque onglet
          }));
          setMenuTabs(tabsArray); // Met à jour l'état avec les onglets récupérés
        } else {
          console.log("Aucun onglet trouvé dans la base de données");
        }
      })
      .catch((error) => {
        setToast({
          show: true,
          message: `Erreur lors de la récupération des onglets : ${error.message}`,
          variant: "danger",
        });
        console.error("Erreur de récupération des onglets :", error);
      });
  }, []);

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setToast({ ...toast, show: false })}
          show={toast.show}
          delay={3000}
          autohide
          bg={toast.variant}
        >
          <Toast.Body>{toast.message}</Toast.Body>
        </Toast>
      </ToastContainer>

      <section className="pricing-book tabs position-re">
        <div
          className="background bg-img bg-fixed section-padding"
          data-background="img/slider/28.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-head text-center">
                  <div className="section-subtitle">Pricing Plan</div>
                  <div className="section-title white">
                    Pricing <span>List</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="tab-links mb-60">
                  <ul className="text-center">
                    {menuTabs.map((tab, index) => (
                      <li
                        key={index}
                        className={`item-link ${index === 0 ? "current" : ""}`}
                        data-tab={tab.id}
                      >
                        {tab.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {menuTabs.map((tab, index) => (
              <div
                key={tab.id}
                className={`row justify-content-center tab-content ${
                  index === 0 ? "current" : ""
                }`}
                id={tab.id}
              >
                <div className="col-md-5">
                  <div className="menu-list mb-20">
                    {tab.items && tab.items.map((item, i) => (
                      <div key={i} className="item">
                        <div className="flex">
                          <div className="title">{item.title}</div>
                          <div className="dots"></div>
                          <div className="price">{item.price} USD</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Section;
