import React, { useState } from "react";
import { Dialog } from "@headlessui/react";

function BlogCard({ blog }) {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white">
      {/* Image de la carte */}
      <img
        className="w-full h-48 object-cover"
        src={blog.image || "https://via.placeholder.com/400"}
        alt="Card image"
      />

      {/* Contenu de la carte */}
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{blog.title}</div>
        <p className="text-gray-700 text-base">
          {blog.description.slice(0, 100)}...
        </p>
      </div>

      {/* Bouton pour ouvrir une modal */}
      <div className="px-6 py-4 flex justify-end">
        <button
          onClick={openModal}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
        >
          Plus d'infos
        </button>
      </div>

      {/* Modal */}
      <Dialog open={isOpen} onClose={closeModal}>
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <Dialog.Panel className="bg-white p-6 rounded-lg max-w-lg w-full">
            <Dialog.Title className="text-2xl font-bold">{blog.title}</Dialog.Title>
            <Dialog.Description className="mt-2 text-gray-600">
              {blog.description}
            </Dialog.Description>
            <div className="mt-4">
              <button
                onClick={closeModal}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              >
                Fermer
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}

function BlogList({ blogs }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 p-4">
      {blogs.map((blog) => (
        <BlogCard key={blog.id} blog={blog} />
      ))}
    </div>
  );
}

export default BlogList;
