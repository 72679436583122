import React from "react";
import Footer from "../components/footer";
import TopNav from "../components/topnavbar";
import Navbar from "../components/navbar";

function Contact() {
  return (
    <>
      <TopNav />
      <Navbar />
      <div
        class="banner-header valign bg-img bg-fixed"
        data-overlay-dark="4"
        data-background="img/slider/25.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-end caption">
              <h1>Make an enquiry</h1>
              <h5>Get in touch with us</h5>
            </div>
          </div>
        </div>
      </div>

      <section class="info-box section-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-head mb-20">
                <div class="section-subtitle">Contact Info</div>
                <div class="section-title">Get In Touch</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="section-head mb-30">
                <p>
                  To submit an enquiry or to arrange an appointment with one of
                  our tailors please call us on 800 123 4444, or alternatively
                  please complete the form below. We will call you back as soon
                  as possible.
                </p>
              </div>
              <div class="item">
                {" "}
                <span class="icon ti-location-pin"></span>
                <div class="cont">
                  <h5>Address</h5>
                  <p>0665 Broadway NY, 10001 USA</p>
                </div>
              </div>
              <div class="item">
                {" "}
                <span class="icon flaticon-phone-call"></span>
                <div class="cont">
                  <h5>Phone</h5>
                  <p>
                    <a href="tel:8551004444">800 123 4444</a>
                  </p>
                </div>
              </div>
              <div class="item">
                {" "}
                <span class="icon ti-email"></span>
                <div class="cont">
                  <h5>e-Mail</h5>
                  <p>info@tailorand.com</p>
                </div>
              </div>
            </div>
            <div class="col-md-5 offset-md-1">
              <div class="contact-form bg-lightbrown">
                <div class="booking-inner clearfix">
                  <form
                    method="post"
                    class="form1 clearfix contact__form"
                    action="https://duruthemes.com/demo/html/tailorand/tailorand-slider/mail.php"
                  >
                    <div class="row">
                      <div class="col-12">
                        <div
                          class="alert alert-success contact__msg"
                          style={{display: "none"}}
                          role="alert"
                        >
                          Your message was sent successfully.
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="input1_wrapper">
                          <label>Name</label>
                          <div class="input2_inner">
                            <input
                              type="text"
                              class="form-control input"
                              placeholder="Name"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input1_wrapper">
                          <label>Phone</label>
                          <div class="input2_inner">
                            <input
                              type="text"
                              class="form-control input"
                              placeholder="Phone"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input1_wrapper">
                          <label>e-Mail</label>
                          <div class="input2_inner">
                            <input
                              type="email"
                              class="form-control input"
                              placeholder="e-Mail"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input1_wrapper">
                          <label>Subject</label>
                          <div class="input2_inner">
                            <input
                              type="text"
                              class="form-control input"
                              placeholder="Subject"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 form-group">
                        <textarea
                          name="message"
                          id="message"
                          cols="30"
                          rows="4"
                          placeholder="Message"
                          required
                        ></textarea>
                      </div>
                      <div class="col-md-12 mb-30">
                        <button class="button-1">
                          <span>Send Message</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
