import React from "react";
import Footer from "../components/footer";
import TopNav from "../components/topnavbar";
import Navbar from "../components/navbar";

function Blog() {
  return (
    <>
      <TopNav />
      <Navbar />
      <div
        class="banner-header valign bg-img bg-fixed"
        data-overlay-dark="4"
        data-background="img/slider/25.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-end caption">
              <h1>
                News <span>&</span> Blog
              </h1>
              <h5>Tailor Industry</h5>
            </div>
          </div>
        </div>
      </div>
      <section class="blog section-padding bg-lightbrown2">
        <div class="container">
          <div class="row">
            <div class="col-md-6 mb-30">
              <div class="item">
                <div class="position-re o-hidden">
                  {" "}
                  <img src="img/slider/2.jpg" alt="" />
                  <div class="date">
                    <a href="post.html">
                      {" "}
                      <span>Dec</span> <i>29</i>{" "}
                    </a>
                  </div>
                </div>
                <div class="con">
                  {" "}
                  <span class="category">
                    <a href="blog.html">Suit Jacket</a>
                  </span>
                  <h5>
                    <a href="post.html">
                      7 Tailoring Tips In Choosing an Off-The-Rack Suit Jacket
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-30">
              <div class="item">
                <div class="position-re o-hidden">
                  {" "}
                  <img src="img/slider/3.jpg" alt="" />
                  <div class="date">
                    <a href="post.html">
                      {" "}
                      <span>Dec</span> <i>27</i>{" "}
                    </a>
                  </div>
                </div>
                <div class="con">
                  {" "}
                  <span class="category">
                    <a href="blog.html">Wool Suits</a>
                  </span>
                  <h5>
                    <a href="post.html">
                      The Smart Dresser’s Guide to Purchasing Wool Suits – What
                      to Know
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-30">
              <div class="item">
                <div class="position-re o-hidden">
                  {" "}
                  <img src="img/slider/9.jpg" alt="" />
                  <div class="date">
                    <a href="post.html">
                      {" "}
                      <span>Dec</span> <i>25</i>{" "}
                    </a>
                  </div>
                </div>
                <div class="con">
                  {" "}
                  <span class="category">
                    <a href="blog.html">Bespoke Tailoring</a>
                  </span>
                  <h5>
                    <a href="post.html">
                      Understanding What Bespoke Tailoring Truly Means – What to
                      Know
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-30">
              <div class="item">
                <div class="position-re o-hidden">
                  {" "}
                  <img src="img/slider/19.jpg" alt="" />
                  <div class="date">
                    <a href="post.html">
                      {" "}
                      <span>Dec</span> <i>23</i>{" "}
                    </a>
                  </div>
                </div>
                <div class="con">
                  {" "}
                  <span class="category">
                    <a href="blog.html">Business Suits</a>
                  </span>
                  <h5>
                    <a href="post.html">
                      Our Guide to Wearing Business Suits Properly – 4 Mistakes
                      to Avoid
                    </a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <ul class="blog-pagination-wrap align-center mb-30 mt-30">
                <li>
                  <a href="blog.html#">
                    <i class="ti-angle-left"></i>
                  </a>
                </li>
                <li>
                  <a href="blog.html#">1</a>
                </li>
                <li>
                  <a href="blog.html#" class="active">
                    2
                  </a>
                </li>
                <li>
                  <a href="blog.html#">3</a>
                </li>
                <li>
                  <a href="blog.html#">
                    <i class="ti-angle-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Blog;
