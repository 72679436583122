import React from "react";

function Blog() {
    return(
        <>
        <section class="blog section-padding">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-head text-center">
                        <div class="section-subtitle">Latest news</div>
                        <div class="section-title white">News <span>&</span> Blog</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="owl-carousel owl-theme">
                        <div class="item">
                            <div class="position-re o-hidden"> <img src="img/slider/2.jpg" alt=""/>
                                <div class="date">
                                    <a href="post.html"> <span>Dec</span> <i>29</i> </a>
                                </div>
                            </div>
                            <div class="con"> <span class="category">
                                    <a href="blog.html">Suit Jacket</a>
                                </span>
                                <h5><a href="post.html">7 Tailoring Tips In Choosing an Off-The-Rack Suit Jacket</a></h5>
                            </div>
                        </div>
                        <div class="item">
                            <div class="position-re o-hidden"> <img src="img/slider/3.jpg" alt=""/>
                                <div class="date">
                                    <a href="post.html"> <span>Dec</span> <i>27</i> </a>
                                </div>
                            </div>
                            <div class="con"> <span class="category">
                                    <a href="blog.html">Wool Suits</a>
                                </span>
                                <h5><a href="post.html">The Smart Dresser’s Guide to Purchasing Wool Suits – What to Know</a></h5>
                            </div>
                        </div>
                        <div class="item">
                            <div class="position-re o-hidden"> <img src="img/slider/9.jpg" alt=""/>
                                <div class="date">
                                    <a href="post.html"> <span>Dec</span> <i>25</i> </a>
                                </div>
                            </div>
                            <div class="con"> <span class="category">
                                    <a href="blog.html">Bespoke Tailoring</a>
                                </span>
                                <h5><a href="post.html">Understanding What Bespoke Tailoring Truly Means – What to Know</a></h5>
                            </div>
                        </div>
                        <div class="item">
                            <div class="position-re o-hidden"> <img src="img/slider/19.jpg" alt=""/>
                                <div class="date">
                                    <a href="post.html"> <span>Dec</span> <i>23</i> </a>
                                </div>
                            </div>
                            <div class="con"> <span class="category">
                                    <a href="blog.html">Business Suits</a>
                                </span>
                                <h5><a href="post.html">Our Guide to Wearing Business Suits Properly – 4 Mistakes to Avoid</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
    );
}

export default Blog;