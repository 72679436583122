import React from "react";

function Forms() {
    return(
        <>
         <section class="appointment">
        <div class="background bg-img bg-fixed section-padding" data-background="img/slider/1.jpg" data-overlay-dark="4">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 mb-30 mt-90">
                        <h5>To submit an enquiry or to arrange an appointment with one of our tailors please call us or alternatively please complete the form.</h5>
                        <div class="reservations">
                            <div class="icon color-1"><span class="flaticon-phone-call"></span></div>
                            <div class="text">
                                <p class="color-1">Get in touch</p> <a class="color-1" href="tel:855-100-4444">800 123 4444</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 offset-md-1">
                        <div class="booking-box">
                            <div class="head-box text-center">
                                <h4>Make An Appointment</h4>
                            </div>
                            <div class="booking-inner clearfix">
                                <form class="form1 clearfix">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="input1_wrapper">
                                                <label>Name</label>
                                                <div class="input2_inner">
                                                    <input type="text" class="form-control input" placeholder="Name" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="input1_wrapper">
                                                <label>Phone</label>
                                                <div class="input2_inner">
                                                    <input type="text" class="form-control input" placeholder="Phone" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="input1_wrapper">
                                                <label>e-Mail</label>
                                                <div class="input2_inner">
                                                    <input type="email" class="form-control input" placeholder="e-Mail" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="input1_wrapper">
                                                <label>Subject</label>
                                                <div class="input2_inner">
                                                    <input type="text" class="form-control input" placeholder="Subject" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="input1_wrapper">
                                                <label>Message</label>
                                                <div class="input2_inner">
                                                    <textarea name="message" id="message" cols="30" rows="3" placeholder="Message" required=""></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <button type="submit" class="btn-form1-submit mt-15">Make Appointment</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
    );
}

export default Forms;