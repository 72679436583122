import React from "react";

function History() {
    return(

        <>
        <section class="about section-padding bg-darkbrown">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-5 animate-box" data-animate-effect="fadeInLeft"> <img src="img/500.png" alt=""/> </div>
                <div class="col-md-5 valign animate-box" data-animate-effect="fadeInRight">
                    <div class="row">
                        <div class="col-md-12 mt-30 mb-30">
                            <div class="section-head mb-20">
                                <div class="section-subtitle">Ten Decades of Experience</div>
                                <div class="section-title white">Tailoring you can depend on</div>
                            </div>
                            <p>Bespoke Tailor tailoring ravida haretra nuam the duru miss uctus the drana accumsan justo aliquam sit amet auctor orci done vitae risus duise nisan sapien silver in the mauris sapien.</p>
                            <div class="about-bottom"> <img src="img/signature.svg" alt="" class="image about-signature"/>
                                <div class="about-name-wrapper">
                                    <div class="about-rol">Tailor, Founder</div>
                                    <div class="about-name">Anthony Martin</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        
        </>
    );
}

export default History;