import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Card, Toast, ToastContainer } from "react-bootstrap";
import { getDatabase, ref, set, push } from "firebase/database";
import { app } from "./firebase"; // Importez votre fichier de configuration Firebase

// Initialisation de Firebase Realtime Database
const db = getDatabase(app);

const FormComponent = () => {
  const [tabId, setTabId] = useState("");
  const [tabName, setTabName] = useState("");
  const [items, setItems] = useState([{ title: "", price: "" }]);
  const [toast, setToast] = useState({ show: false, message: "", variant: "" });

  // Ajouter un nouvel item
  const handleAddItem = () => {
    setItems([...items, { title: "", price: "" }]);
  };

  // Gestion des modifications des champs d'items
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;
    setItems(updatedItems);
  };

  // Validation du formulaire
  const validateForm = () => {
    if (!tabId.trim() || !tabName.trim()) return false;
    return !items.some((item) => !item.title.trim() || !item.price.trim());
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setToast({
        show: true,
        message: "Veuillez remplir tous les champs correctement.",
        variant: "danger",
      });
      return;
    }

    try {
      // Utilisation de Firebase Realtime Database pour enregistrer les données
      const newTabRef = push(ref(db, "menuTabs"));
      await set(newTabRef, {
        id: tabId,
        name: tabName,
        items,
        timestamp: new Date().toISOString(),
      });

      setToast({
        show: true,
        message: "Les informations ont été enregistrées avec succès.",
        variant: "success",
      });

      // Réinitialiser le formulaire
      setTabId("");
      setTabName("");
      setItems([{ title: "", price: "" }]);
    } catch (error) {
      setToast({
        show: true,
        message: `Erreur lors de la soumission : ${error.message}`,
        variant: "danger",
      });
      console.error("Erreur lors de l'ajout :", error);
    }
  };

  return (
    <Container className="mt-5">
      {/* Notifications */}
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setToast({ ...toast, show: false })}
          show={toast.show}
          delay={3000}
          autohide
          bg={toast.variant}
        >
          <Toast.Body>{toast.message}</Toast.Body>
        </Toast>
      </ToastContainer>

      {/* Formulaire */}
      <Card className="p-4">
        <Form onSubmit={handleSubmit}>
          {/* ID de l'onglet */}
          <Form.Group className="mb-3">
            <Form.Label>ID de l'onglet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Entrez l'ID de l'onglet (ex. tab-1)"
              value={tabId}
              onChange={(e) => setTabId(e.target.value)}
            />
          </Form.Group>

          {/* Nom de l'onglet */}
          <Form.Group className="mb-3">
            <Form.Label>Nom de l'onglet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Entrez le nom de l'onglet (ex. Trousers)"
              value={tabName}
              onChange={(e) => setTabName(e.target.value)}
            />
          </Form.Group>

          {/* Items */}
          <Form.Group className="mb-3">
            <Form.Label>Items</Form.Label>
            {items.map((item, index) => (
              <Row key={index} className="mb-2">
                <Col md={6}>
                  <Form.Control
                    type="text"
                    placeholder="Titre de l'item"
                    value={item.title}
                    onChange={(e) =>
                      handleItemChange(index, "title", e.target.value)
                    }
                  />
                </Col>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    placeholder="Prix de l'item (ex. $50.00)"
                    value={item.price}
                    onChange={(e) =>
                      handleItemChange(index, "price", e.target.value)
                    }
                  />
                </Col>
              </Row>
            ))}
            <Button variant="secondary" onClick={handleAddItem} className="mt-2">
              Ajouter un item
            </Button>
          </Form.Group>

          {/* Bouton de soumission */}
          <Button variant="primary" type="submit" className="w-100">
            Soumettre
          </Button>
        </Form>
      </Card>
    </Container>
  );
};

export default FormComponent;
