import React, { useState } from "react";

function TopNav () {
    return(
        <>
        <div class="main-header">
        <div class="header-top">
            <div class="container">
                <div class="top-outer clearfix">
                    <div class="top-left">
                        <ul class="links clearfix">
                            <li><a href="#0"><span class="flaticon-maps-and-flags"></span>Akpakpa Lomnava Lot 74</a></li>
                            <li><a href="tel: +229 01 61 05 78 36"><span class="flaticon-phone-call"></span>+229 01 61 05 78 36</a></li>
                        </ul>
                    </div>
                    <div class="top-right clearfix">
                        <ul class="links clearfix">
                            <li><span class="ti-time"></span>Opening : Mon-Fri 10:00 - 20:00</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
        
        </>
    );
}

export default TopNav;